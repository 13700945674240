export const API_BASE_URL = 'https://api.walshop.ru';
// export const API_BASE_URL = 'http://localhost:3001';
export const API_MOVIES_URL = 'https://api.nomoreparties.co';
export const UNAUTHORIZED_ERROR_CODE = 401;
export const SHORT_MOVIE_MIN_DURATION = 40;
export const INITIAL_CARDS_QUANTITY_MOBILE = 5;
export const INITIAL_CARDS_QUANTITY_TABLET = 8;
export const INITIAL_CARDS_QUANTITY_DESKTOP = 12;
export const EXPAND_CARDS_QUANTITY_MOBILE = 2;
export const EXPAND_CARDS_QUANTITY_TABLET = 2;
export const EXPAND_CARDS_QUANTITY_DESKTOP = 3;
